import { createSlice } from "@reduxjs/toolkit";

export type userState = {
  haveUser: boolean;
  user: any;
  accesToken: string;
};

const INIT_STATE: userState = {
  haveUser: false,
  user: {},
  accesToken: "",
};

const reducer = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    setUser: (state, action) => {
      return {
        ...state,
        haveUser: true,
        user: action.payload.user,
        accesToken: action.payload.accesToken,
      };
    },
  },
});

export const { actions: userAction, reducer: userReducer } = reducer;
