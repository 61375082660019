import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createWalletNodeRecord,
  getWalletInfo,
  getWalletNodes,
  getWalletNodesCategory,
} from "./call";
import { userState } from "../../../redux/user/reducer";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { NodeType, WalletStore } from "../../../redux/wallet/reducer";
import "./walletPage.css";
import WalletBalance from "./WalletBalance";
import ListWalletNotes from "./ListWalletNotes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function WalletPage() {
  const dispatch = useDispatch();
  let { walletId } = useParams();
  const wallet: WalletStore = useSelector((state: any) => state.wallet);
  const user: userState = useSelector((state: any) => state.user);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [WalletNodeCategory, setWalletNodeCategory] = React.useState("");
  const [walletNodeType, setWalletNodeType] = React.useState<NodeType>(
    NodeType.expense
  );

  const handleChangeNodeCategory = (event: SelectChangeEvent) => {
    setWalletNodeCategory(event.target.value as string);
  };

  const handleChangeNodeType = (event: SelectChangeEvent) => {
    setWalletNodeType(event.target.value as NodeType);
    setWalletNodeCategory("");
  };

  const createNodeRecord = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const nodeType = data.get("select-node-type") as string;
    const amount =
      Number(data.get("amount")) * (nodeType === "expense" ? -1 : 1);
    const date = data.get("date") as string;
    const note = data.get("note") as string;
    const nodeCategoryId = Number(data.get("nodeCategoryId"));
    if (walletId) {
      const result = await createWalletNodeRecord(parseInt(walletId), user, {
        amount,
        date,
        note,
        wallet_note_category_id: nodeCategoryId,
      });

      if (result.success) {
        await getWalletNodes(parseInt(walletId), user, { dispatch });
        handleClose();
      } else {
        console.log(result);
      }
    }
  };

  useEffect(() => {
    if (walletId) {
      getWalletInfo(parseInt(walletId), user, { dispatch });
    }
  }, [walletId]);

  useEffect(() => {
    if (walletId) {
      getWalletNodes(parseInt(walletId), user, { dispatch });
    }
  }, []);

  useEffect(() => {
    if (walletId) {
      getWalletNodesCategory(parseInt(walletId), user, { dispatch });
    }
  }, []);

  return (
    <div className="walletPage">
      <h2 className="walletTitle">
        {wallet.selectedWallet?.wallet_name} : {wallet.selectedWallet?.currency}
      </h2>

      <WalletBalance />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleOpen}>Add Node</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            component="form"
            onSubmit={createNodeRecord}
            autoComplete="off"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Node
            </Typography>
            <Box
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
            >
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-node-type-title">Node type</InputLabel>
                  <Select
                    required
                    labelId="select-node-type"
                    id="select-node-type"
                    name="select-node-type"
                    value={walletNodeType}
                    label="Node Type"
                    onChange={handleChangeNodeType}
                  >
                    <MenuItem value="income">income</MenuItem>
                    <MenuItem value="expense">expense</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <TextField
                required
                id="walletPage-modal-input-amount"
                label="Amount"
                name="amount"
                variant="standard"
                type="number"
              />
              <TextField
                id="walletPage-modal-input-note"
                label="Note"
                name="note"
                variant="standard"
              />
              <TextField
                required
                id="walletPage-modal-input-x"
                variant="standard"
                type="date"
                name="date"
              />
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Node Category
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={WalletNodeCategory}
                    label="Node Category"
                    onChange={handleChangeNodeCategory}
                    name="nodeCategoryId"
                  >
                    {wallet.walletNodeCategories[walletNodeType].map(
                      (nodeType) => (
                        <MenuItem
                          key={nodeType.wallet_note_category_id}
                          value={nodeType.wallet_note_category_id}
                        >
                          {nodeType.note_category_name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              style={{
                justifyContent: "space-between",
                display: "flex",
                margin: "8px",
              }}
            >
              <Button variant="contained" color="info" type="submit">
                Create Node
              </Button>
              <Button color="warning" variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>

      <ListWalletNotes />
    </div>
  );
}

export default WalletPage;
