import {
  Box,
  Button,
  Collapse,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  ClickAwayListener,
  MenuList,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WalletStore, walletNodes } from "../../../redux/wallet/reducer";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import moment from "moment";
import { deleteWalletNode, getWalletNodes } from "./call";
import { userState } from "../../../redux/user/reducer";

export type RowDataType = {
  date: string;
  node_id: number;
  start: number;
  expense: number;
  income: number;
  end: number;
  details: WalletRowDetail[];
};

export type WalletRowDetail = {
  amount: number;
  node_id: number;
  note: string;
  icon: string;
  color: string;
};

export const walletNodeTableSize = {
  icon: 50,
  amount: 240,
};

function Row(props: { row: RowDataType }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [currentButton, setCurrentButton] =
    React.useState<HTMLButtonElement | null>(null);
  const [selectedNode, setSelectedNode] = React.useState<number>(0);
  const wallet: WalletStore = useSelector((state: any) => state.wallet);
  const user: userState = useSelector((state: any) => state.user);

  const dispatch = useDispatch();

  const handleToggle = (event: any, node_id: number) => {
    setSelectedNode(node_id);
    setCurrentButton(event.target);
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (currentButton && currentButton.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleRemoveWalletNode = async (
    event: Event | React.SyntheticEvent
  ) => {
    await deleteWalletNode(user.user, {
      node_id: selectedNode,
    });
    handleClose(event);
    await getWalletNodes(wallet.selectedWallet.id, user, { dispatch });
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    } else if (event.key === "Escape") {
      setOpenMenu(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      currentButton!.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  return (
    <React.Fragment>
      <TableRow
        key={row.date}
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ backgroundColor: "#b5b5b5" }}
      >
        <TableCell
          style={{ fontSize: "12px", padding: "4px 16px", lineHeight: "14px" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUp style={{ width: "16px", height: "16px" }} />
            ) : (
              <KeyboardArrowDown style={{ width: "16px", height: "16px" }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          style={{ fontSize: "12px", padding: "4px 16px", lineHeight: "14px" }}
        >
          {row.date}
        </TableCell>
        <TableCell
          style={{ fontSize: "12px", padding: "4px 16px", lineHeight: "14px" }}
          align="right"
        >
          {row.expense.toLocaleString()}
        </TableCell>
        <TableCell
          style={{ fontSize: "12px", padding: "4px 16px", lineHeight: "14px" }}
          align="right"
        >
          {row.income.toLocaleString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table
                size="small"
                aria-label="purchases"
                style={{ width: "100%", margin: "auto" }}
              >
                <TableBody>
                  {row.details.map((detailDate) => (
                    <TableRow key={detailDate.node_id}>
                      <TableCell width={walletNodeTableSize.icon}>
                        <div>
                          <Button
                            id="composition-button"
                            aria-controls={
                              openMenu ? "composition-menu" : undefined
                            }
                            aria-expanded={openMenu ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleToggle(e, detailDate.node_id);
                            }}
                            style={{ padding: 0, minWidth: 0 }}
                          >
                            ...
                          </Button>
                          <Popper
                            open={openMenu}
                            anchorEl={currentButton}
                            role={undefined}
                            placement="right-end"
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin: "right-end",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={openMenu}
                                      id="composition-menu"
                                      aria-labelledby="composition-button"
                                      onKeyDown={handleListKeyDown}
                                      style={{ zIndex: 1000 }}
                                    >
                                      <MenuItem onClick={handleClose}>
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={handleRemoveWalletNode}
                                      >
                                        Remove
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      </TableCell>
                      <TableCell>
                        {detailDate.icon} | {detailDate.note}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: detailDate.color }}
                        width={walletNodeTableSize.amount}
                      >
                        {detailDate.amount < 0 ? detailDate.amount : ""}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: detailDate.color }}
                        width={walletNodeTableSize.amount}
                      >
                        {detailDate.amount > 0 ? detailDate.amount : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ListWalletNotes() {
  const walletNodes: walletNodes = useSelector(
    (state: any) => state?.wallet?.walletNodes
  );

  const [nodeRows, setNodeRows] = useState<any[]>([]);

  useEffect(() => {
    const { walletNodes: listNodes } = walletNodes;
    if (listNodes) {
      const dateData = listNodes.reduce((result, current) => {
        const { amount, note, icon, note_category_name, date, node_id } =
          current;
        const dateString = moment(date).format("YYYY/MM/DD");

        if (!result[dateString]) {
          result[dateString] = {
            date: dateString,
            node_id: node_id,
            start: 0,
            expense: amount > 0 ? 0 : amount,
            income: amount > 0 ? amount : 0,
            end: 0,
            details: [
              {
                amount,
                node_id,
                note: note || note_category_name,
                icon,
                color: amount > 0 ? "green" : "red",
              },
            ],
          };
        } else {
          if (amount > 0) {
            result[dateString].income += amount;
          } else {
            result[dateString].expense += amount;
          }
          const { income, start, expense } = result[dateString];
          result[dateString].end = start + income + expense;
          result[dateString].details.push({
            amount,
            note: note || note_category_name,
            icon,
            node_id,
            color: amount > 0 ? "green" : "red",
          });
        }
        return result;
      }, {});

      const rowData: walletNodes[] = [];
      for (let dateString in dateData) {
        rowData.push(dateData[dateString]);
      }
      setNodeRows(rowData);
    }
  }, [walletNodes]);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell width={walletNodeTableSize.icon}></TableCell>
              <TableCell>Date / Node</TableCell>
              <TableCell align="right" width={walletNodeTableSize.amount}>
                Expense
              </TableCell>
              <TableCell align="right" width={walletNodeTableSize.amount}>
                Income
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nodeRows.map((row) => (
              <Row key={row.date} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ListWalletNotes;
