import React from "react";

function WalletBalance() {
  return (
    <table className="walletBalance">
      <tr>
        <td>start ballance:</td>
        <td>0</td>
      </tr>
      <tr>
        <td>income:</td>
        <td>0</td>
      </tr>
      <tr>
        <td>expense:</td>
        <td>0</td>
      </tr>
      <tr>
        <td>start ballance:</td>
        <td>0</td>
      </tr>
      <tr>
        <td>current ballance:</td>
        <td>0</td>
      </tr>
    </table>
  );
}

export default WalletBalance;
