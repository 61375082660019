import { get } from "lodash";
import { customAxios } from "../../../axios/customAxios";
import { userAction } from "../../../redux/user/reducer";
import { alertObject } from "../../../components/Alert/CustomAlert";
import { setCookie } from "../../../libs/cookie";

export type LoginAPIInput = {
  body: { email: string; password: string };
  alertObject: alertObject;
  dispatch: any;
  setAlertObject: any;
};

export const loginAPI = async (input: LoginAPIInput) => {
  const { email, password } = input.body;
  const { alertObject, dispatch, setAlertObject } = input;
  try {
    const loginInfo = await customAxios.post("/auth/login", {
      email,
      password,
    });
    const data = loginInfo.data;
    const userInfo = get(data, "userInfo", {});
    const accesToken = get(data, "access_token", "");

    // store user info
    dispatch(userAction.setUser({ user: userInfo, accesToken }));

    // store token on cookie
    setCookie("c_user", accesToken, 1);

    return true;
  } catch (error) {
    const message = get(error, "response.data.message", "error");

    setAlertObject({
      ...alertObject,
      isShow: true,
      type: "error",
      alertText: message,
    });
  }
};
