import React from "react";
import WalletsSummary from "./WalletsSummary";
import "./userPanel.css";
import CustomAlert, {
  DEFAULT_ALERT_OBJECT,
  alertObject,
} from "../../../components/Alert/CustomAlert";

function UserPanel() {
  const [alertObject, setAlertObject] =
    React.useState<alertObject>(DEFAULT_ALERT_OBJECT);

  return (
    <div className="userPanle">
      <CustomAlert alertObject={alertObject} setAlertObject={setAlertObject} />
      <h1>UserPanel</h1>
      {/* list wallet */}
      <WalletsSummary setAlertObject={setAlertObject} />

      {/* list dairy */}
    </div>
  );
}

export default UserPanel;
