import * as React from "react";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { get } from "lodash";
import { customAxios } from "../../../axios/customAxios";
import { useSelector } from "react-redux";
import { DEFAULT_ALERT_OBJECT } from "../../../components/Alert/CustomAlert";

const currencies = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "VND",
    label: "VND",
  },
  {
    value: "JPY",
    label: "JPY",
  },
];

const category = [
  {
    value: "1",
    label: "default",
  },
  {
    value: "2",
    label: "cash",
  },
  {
    value: "3",
    label: "bank",
  },
  {
    value: "",
    label: "add new category",
  },
];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export type WalletModalObjectProps = {
  isShow: boolean;
  modalTitle: string;
};

export type CreateWalletModalProps = {
  modalObject: WalletModalObjectProps;
  setWalletModalObject: any;
  setAlertObject: Function;
};

export default function CreateWalletModal({
  modalObject,
  setWalletModalObject,
  setAlertObject,
}: CreateWalletModalProps) {
  const user = useSelector((state: any) => state.user);
  const token = user.accesToken;

  const [isNewCategory, SetIsNewCategory] = React.useState(false);
  const handleClose = () => {
    setWalletModalObject({ ...modalObject, isShow: false });
    SetIsNewCategory(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const walletName = data.get("new-wallet-name");
    const currency = data.get("currency");
    const categoryId = data.get("select-category");
    const newCategory = data.get("new-category");

    try {
      const res = await customAxios.post(
        "/wallet/create-wallet",
        { walletName, currency, categoryId, newCategory },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.status < 300) {
        setAlertObject({
          ...DEFAULT_ALERT_OBJECT,
          isShow: true,
          type: "success",
          alertText: "createWallet success",
        });

        handleClose();
      }
    } catch (error) {
      setAlertObject({
        ...DEFAULT_ALERT_OBJECT,
        isShow: true,
        type: "error",
        alertText: "createWallet failed: " + error,
      });
    }
  };

  const selectCategory = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    SetIsNewCategory(!e.target.value);
  };

  return (
    <div>
      <Modal
        open={modalObject.isShow}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalObject.modalTitle}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            autoComplete="off"
          >
            <TextField
              required
              id="new-wallet-name"
              name="new-wallet-name"
              label="Wallet Name"
              variant="standard"
              margin="normal"
              fullWidth
              autoFocus
            />

            <TextField
              id="standard-select-currency-native"
              select
              name="currency"
              defaultValue="VND"
              SelectProps={{
                native: true,
              }}
              helperText="Please select your currency"
              variant="standard"
            >
              {currencies.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <TextField
              onChange={selectCategory}
              id="standard-select-currency-native"
              select
              name="select-category"
              defaultValue="Default"
              SelectProps={{
                native: true,
              }}
              helperText="Please select wallet category"
              variant="standard"
            >
              {category.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            {isNewCategory && (
              <TextField
                required
                id="new-category-name"
                name="new-category"
                label="Category Name"
                variant="standard"
                margin="normal"
                fullWidth
                autoFocus
              />
            )}

            <div className="createWalletButton">
              <Button variant="contained" color="info" type="submit">
                Create Wallet
              </Button>
              <Button color="warning" variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
