import { createSlice } from "@reduxjs/toolkit";

export type WalletStore = {
  isLoading: boolean;
  wallet: any[];
  total: number;
  skip: number;
  limit: number;
  selectedWallet: any;
  walletNodes: walletNodes;
  walletNodeCategories: walletNodeCategories;
};

export type walletNodes = {
  page?: number;
  limit?: number;
  totalRecord?: number;
  walletNodes?: any[];
};

export type walletNodeCategories = {
  expense: any[];
  income: any[];
};

export enum NodeType {
  expense = "expense",
  income = "income",
}

const INIT_STATE: WalletStore = {
  isLoading: true,
  wallet: [],
  total: 0,
  skip: 0,
  limit: 0,
  selectedWallet: {},
  walletNodes: {},
  walletNodeCategories: {
    income: [],
    expense: [],
  },
};

const reducer = createSlice({
  initialState: INIT_STATE,
  name: "wallet",
  reducers: {
    GET_WALLET: (state) => state,
    SET_WALLET: (state, action) => {
      return {
        ...state,
        wallet: action.payload.wallet,
        total: action.payload.total,
      };
    },
    SELECT_WALLET: (state, action) => {
      return {
        ...state,
        selectedWallet: action.payload,
      };
    },

    SET_WALLET_NODES: (state, action) => {
      return {
        ...state,
        walletNodes: action.payload,
      };
    },

    SET_WALLET_NODE_CATEGORIES: (state, action) => {
      return {
        ...state,
        walletNodeCategories: action.payload,
      };
    },
  },
});

export const { actions: walletAction, reducer: walletReducer } = reducer;
