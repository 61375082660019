import { customAxios } from "../../../axios/customAxios";
import { checkAndGetToken } from "../../../libs/cookie";
import { userState } from "../../../redux/user/reducer";
import { walletAction } from "../../../redux/wallet/reducer";

export const getWalletInfo = async (
  walletId: number,
  user: userState,
  input: { dispatch: any }
) => {
  try {
    const { dispatch } = input;
    const token = checkAndGetToken(user.accesToken);
    // if (!token) {
    //   navigate("/sign-in");
    // }
    const url = `/wallet/${walletId}`;
    const response = await customAxios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      const action = walletAction.SELECT_WALLET({ ...response.data });
      dispatch(action);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getWalletNodes = async (
  walletId: number,
  user: userState,
  input: { dispatch: any }
) => {
  const { dispatch } = input;
  const token = checkAndGetToken(user.accesToken);

  const url = `/wallet-node/list/${walletId}`;
  const response = await customAxios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response.status === 200) {
    const action = walletAction.SET_WALLET_NODES({ ...response.data });
    dispatch(action);
  }
};

export const getWalletNodesCategory = async (
  walletId: number,
  user: userState,
  input: { dispatch: any }
) => {
  const { dispatch } = input;
  const token = checkAndGetToken(user.accesToken);

  const url = `/wallet-node-category/get-node-category/${walletId}`;
  const response = await customAxios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response.status === 200) {
    const action = walletAction.SET_WALLET_NODE_CATEGORIES({
      ...response.data,
    });
    dispatch(action);
  }
};

export const createWalletNodeRecord = async (
  walletId: number,
  user: userState,
  input: {
    wallet_note_category_id: number;
    note: string;
    date: string;
    amount: number;
  }
) => {
  const token = checkAndGetToken(user.accesToken);
  const { wallet_note_category_id, note, date, amount } = input;

  const url = `/wallet-node/add`;
  const response = await customAxios.post(
    url,
    {
      wallet_id: walletId,
      wallet_note_category_id,
      note,
      date,
      amount,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  if (response.status === 201) {
    return { success: true, error: false };
  } else {
    return { success: false, error: response };
  }
};

export const deleteWalletNode = async (
  user: userState,
  input: { node_id: number }
) => {
  const token = checkAndGetToken(user.accesToken);
  const { node_id } = input;

  const url = `/wallet-node/delete/` + node_id;
  const response = await customAxios.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response.status >= 200 && response.status < 400) {
    return { success: true, error: false };
  } else {
    return { success: false, error: response };
  }
};
