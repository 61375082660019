import { get } from "lodash";
import { customAxios } from "../../../axios/customAxios";
import { userState } from "../../../redux/user/reducer";
import { walletAction } from "../../../redux/wallet/reducer";
import { checkAndGetToken } from "../../../libs/cookie";

export type GetWalletInput = { setWallets: any; dispatch: any; navigate: any };
export type GetWalletQuery = {
  limit?: number;
  skip?: number;
  includes?: string;
};

export const getWallets = async (
  user: userState,
  query: GetWalletQuery,
  input: GetWalletInput
) => {
  const { setWallets, dispatch, navigate } = input;
  const keys = Object.keys(query);
  const queryString = keys
    .map((e: string) => {
      const value = get(query, e);
      return `${e}=${value}`;
    })
    .join("&");

  let url = "/wallet/get-wallets?" + queryString;

  try {
    const token = checkAndGetToken(user.accesToken);
    if (!token) {
      navigate("/sign-in");
    }
    const response = await customAxios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = response.data;
    const { data: records, skip, limit, total } = data;
    setWallets(records);
    dispatch(walletAction.SET_WALLET({ wallet: records, total }));
  } catch (error) {
    console.log(error);
    // const message = get(error, "response.data.message", "error");

    // setAlertObject({
    //   ...alertObject,
    //   isShow: true,
    //   type: "error",
    //   alertText: message,
    // });
  }
};

export const x = 10;
