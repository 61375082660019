import * as React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link as DLink, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  Avatar,
  CssBaseline,
  Checkbox,
  Link,
  Grid,
  Box,
  FormControlLabel,
  TextField,
} from "@mui/material";
import CustomAlert, {
  alertObject,
} from "../../../components/Alert/CustomAlert";
import { useDispatch } from "react-redux";
import { loginAPI } from "./call";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alertObject, setAlertObject] = React.useState<alertObject>({
    isShow: false,
    type: "warning",
    alertText: "warning",
    interval: 4000,
    location: { vertical: "top", horizontal: "center" },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    const password = data.get("password") as string;
    if (!email || !password) {
      setAlertObject({
        ...alertObject,
        isShow: true,
        type: "warning",
        alertText: "email and password cannot empty",
      });
      return;
    }

    try {
      const isLogin = await loginAPI({
        alertObject,
        setAlertObject,
        dispatch,
        body: { email, password },
      });
      if (isLogin) {
        navigate("/user-panel");
      }
    } catch (error) {
      setAlertObject({
        ...alertObject,
        isShow: true,
        type: "error",
        alertText: "error: " + error,
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {alertObject.isShow && (
          <CustomAlert
            alertObject={alertObject}
            setAlertObject={setAlertObject}
          />
        )}

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <DLink to="/forgot-password">
                  <Link variant="body2">Forgot password?</Link>
                </DLink>
              </Grid>
              <Grid item>
                <DLink to="/sign-up">
                  <Link variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </DLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
