import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./redux/index";
import Home from "./pages/Home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ErrorNotFound from "./pages/Error/NotFound/ErrorNotFound";
import UserPanel from "./pages/User/UserPanel/UserPanel";
import Login from "./pages/Auth/Login/Login";
import SignUp from "./pages/Auth/SignUp/SignUp";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import WalletPage from "./pages/User/WalletPage/WalletPage";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/sign-in" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/user-panel" element={<UserPanel />} />
          <Route path="/wallet/:walletId" element={<WalletPage />} />
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
