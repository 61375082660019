import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export type alertObject = {
  isShow: boolean;
  type: "warning" | "error" | "info" | "success";
  alertText: string;
  interval: number;
  location: {
    vertical: "top" | "bottom";
    horizontal: "left" | "right" | "center";
  };
};

export const DEFAULT_ALERT_OBJECT: alertObject = {
  isShow: false,
  type: "info",
  alertText: "",
  interval: 3000,
  location: {
    vertical: "top",
    horizontal: "center",
  },
};

export type CustomAlertProps = {
  alertObject: alertObject;
  setAlertObject: Function;
};

export default function CustomAlert({
  alertObject,
  setAlertObject,
}: CustomAlertProps) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertObject({
      ...alertObject,
      isShow: false,
    });
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={alertObject.isShow}
        autoHideDuration={alertObject.interval}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertObject.type}
          sx={{ width: "100%" }}
        >
          {alertObject.alertText}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
