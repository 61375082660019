import React, { useState, useEffect } from "react";
import WalletCard, { WalletCardProps } from "./WalletCard";
import { Button } from "@mui/material";
import CreateWalletModal, { WalletModalObjectProps } from "./CreateWalletModal";
import { GetWalletQuery, getWallets } from "./call";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import { WalletStore } from "../../../redux/wallet/reducer";
import { userState } from "../../../redux/user/reducer";
import { useNavigate } from "react-router-dom";

export type WalletsSummaryProps = { setAlertObject: Function };

function WalletsSummary({ setAlertObject }: WalletsSummaryProps) {
  const navigate = useNavigate();
  const user: userState = useSelector((e: any) => {
    return e.user;
  });

  const wallet: WalletStore = useSelector((e: any) => {
    return e.wallet;
  });

  const dispatch = useDispatch();
  const [wallets, setWallets] = useState([]);
  const [walletModalObject, setWalletModalObject] =
    useState<WalletModalObjectProps>({
      isShow: false,
      modalTitle: "Create New Wallet",
    });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openWalletModal = () => {
    setWalletModalObject({ ...walletModalObject, isShow: true });
  };

  useEffect(() => {
    const params: GetWalletQuery = { limit: rowsPerPage };
    if (page * rowsPerPage) {
      params.skip = page * rowsPerPage;
    }

    getWallets(user, params, { setWallets, dispatch, navigate });
  }, [page, rowsPerPage, user]);

  return (
    <div>
      <div className="listWalletTitle">
        <h2>Wallets</h2>
        <Button size="small" onClick={openWalletModal}>
          New Wallet
        </Button>
      </div>
      <div className="listCard">
        {wallets.map((e: WalletCardProps) => {
          return <WalletCard key={e.id} {...e} />;
        })}
      </div>

      <TablePagination
        component="div"
        count={wallet.total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CreateWalletModal
        modalObject={walletModalObject}
        setWalletModalObject={setWalletModalObject}
        setAlertObject={setAlertObject}
      />
    </div>
  );
}

export default WalletsSummary;
