import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import { walletAction } from "../../../redux/wallet/reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export type WalletCardProps = {
  id?: number;
  currency?: string;
  wallet_name?: string;
  category_name?: string;
  icon?: string;
};

function WalletCard({
  currency,
  wallet_name,
  category_name,
  icon,
  id,
}: WalletCardProps) {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const selectWallet = () => {
    const action = walletAction.SELECT_WALLET({
      currency,
      wallet_name,
      category_name,
      icon,
      id,
    });
    dispatch(action);
    nav(`/wallet/${id}`);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {category_name}
        </Typography>
        <Typography variant="h5" component="div">
          {wallet_name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {currency}
        </Typography>
        <img src={icon} alt="" />
      </CardContent>
      <CardActions>
        <Button onClick={selectWallet} size="small">
          View Detail
        </Button>
      </CardActions>
    </Card>
  );
}

export default WalletCard;
