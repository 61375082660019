import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { walletAction } from "../redux/wallet/reducer";
import { Link } from "react-router-dom";

function Home() {
  const dispatch = useDispatch();
  const wallet = useSelector((state: any) => {
    return state.wallet.wallet;
  });

  const handleSignUp = () => {
    dispatch(walletAction.SET_WALLET({ wallet: [3, 2, 1, 5] }));
  };

  return (
    <div>
      <h1>Home</h1>
      <Link to="sign-in">
        <button>Sign In</button>
      </Link>
      <Link to="sign-up">
        <button>Sign Up</button>
      </Link>
      <button onClick={handleSignUp}>Click</button>
      {wallet.map((e: any) => (
        <div>{e}</div>
      ))}
    </div>
  );
}

export default Home;
